.theme-boost * .app-title h1 {
  font-size: 3.4rem;
  line-height: 1.2;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .theme-boost * .app-title h1 {
    font-size: 4.8rem;
  }
}
