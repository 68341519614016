.theme-boost * .whd-card,
.theme-boost * .whd-card_ineligble h3 {
  font-size: 1.6rem;
}

.theme-boost * .whd-card_label {
  font-weight: 600;
}

.theme-boost * .whd-card_value-check::before {
  content: url('../../themes/boost/tick.svg');
  margin-right: 1rem;
  position: relative;
  top: 0.1rem;
}

@media screen and (min-width: 768px) {
  .theme-boost * .whd-card_label {
    font-weight: 700;
  }
}
