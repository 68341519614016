@import 'WhdCardClosed-ovo.css';
@import 'WhdCardClosed-boost.css';

.whd-card-closed {
  line-height: 1.5;
  font-size: 1.4rem;
}

.whd-card-closed p,
.whd-card-closed_whats-this {
  margin: 0 auto 2.4rem auto;
}

.whd-card-closed_whats-this p {
  margin: 0 auto 1.6rem auto;
}

.whd-card-closed_whats-this > div div {
  background-color: #00000040;
}

.details-card * .whd-card-closed_notification p:last-of-type {
  margin: 0;
}

.whd-card-closed_learn-more h3 {
  font-size: 1.6rem;
  color: #2f3749;
  margin: 0 auto 0.8rem auto;
}

@media screen and (min-width: 480px) {
  .whd-card-closed {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 768px) {
  .whd-card-closed_whats-this p {
    margin: 0 auto 2.4rem auto;
  }
}
