@import 'DetailsCard-ovo';
@import 'DetailsCard-boost';

.details-card h2 {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 1.6rem;
}

.details-card p {
  margin: 0 auto 0.8rem auto;
}

.details-card p:last-of-type {
  margin: 0 auto 2.4rem auto;
}

.details-card .info-button {
  margin-top: 0rem;
}

@media screen and (min-width: 768px) {
  .details-card {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 3.2rem;
    margin-bottom: 2rem;
  }

  .details-card h2 {
    margin-bottom: 2.4rem;
  }
}
