@media screen and (max-width: 767px) {
  .theme-ovo * .anchor-tag {
    background: #0d8426;
    color: #ffffff;
    border-radius: 0.4rem;
    font-size: 1.4rem;
    font-family: Montserrat, sans-serif;
  }

  .theme-ovo * .anchor-tag::after {
    content: url('../../common/images/chevron.svg');
    margin-top: 0.8rem;
  }
}

@media screen and (min-width: 768px) {
  .theme-ovo * .anchor-tag {
    color: #0d8426;
    font-size: 1.6rem;
    font-family: 'Open Sans', sans-serif;
  }
}
