.theme-ovo * .whd-card,
.theme-ovo * .whd-card_ineligble h3 {
  font-size: 1.4rem;
}

.theme-ovo * .whd-card_value {
  font-family: Montserrat, sans-serif;
}

.theme-ovo * .whd-card_value-check::before {
  content: url('../../themes/ovo/tick.svg');
  margin-right: 1rem;
  position: relative;
  top: 0.2rem;
}

@media screen and (min-width: 480px) {
  .theme-ovo * .whd-card,
  .theme-ovo * .whd-card_ineligble h3 {
    font-size: 1.6rem;
  }
}
