@import 'ScrollToTopButton-ovo';
@import 'ScrollToTopButton-boost';

.scroll-to-top-button {
  display: none;
}

@media screen and (min-width: 768px) {
  .scroll-to-top-button {
    position: fixed;
    top: 50%;
    left: calc(50% + 40rem);
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
    display: block;
    margin: 0;
    padding: 0;
  }

  .scroll-to-top-button.scroll-to-top-button-visible {
    visibility: visible;
    opacity: 1;
  }
}
