.theme-boost * .account-details {
  background-image: url('../../themes/boost/small_bg.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.theme-boost * .account-details_form-section .form-section_content {
  margin-top: 4rem;
}

@media screen and (min-width: 768px) {
  .theme-boost * .account-details {
    background-image: url('../../themes/boost/large_bg.svg');
    background-size: auto;
  }

  .theme-boost * .account-details_form-section .form-section_content {
    margin-top: 8rem;
  }
}
