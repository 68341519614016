.theme-ovo * .info-button {
  border-radius: 0.4rem;
  background: #0d8426;
  color: #ffffff;
}

.theme-ovo * .info-button-alternative {
  background: #de1d8d;
  color: #ffffff;
}

.theme-ovo * .info-button::after {
  content: url('../../common/images/chevron.svg');
  margin-top: 4px;
}
