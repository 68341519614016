.theme-boost * .next-button {
  margin-top: 2.4rem;
}

.theme-boost * .next-button button {
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  border-radius: 2.6rem;
  padding-inline: 3rem;
}
