.theme-ovo * .account-details {
  background-image: url('../../themes/ovo/small_green_bg.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
}

@media screen and (min-width: 768px) {
  .theme-ovo * .account-details {
    background-image: url('../../themes/ovo/large_green_bg.svg');
  }
}

@media only screen and (hover: none) and (pointer: coarse) {
  .theme-ovo * .account-details input:focus {
    font-size: 1.6rem;
  }
}

.theme-ovo * .account-details_logos {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2rem;
}

@media screen and (min-width: 768px) {
  .theme-ovo * .account-details_logos {
    margin-bottom: 3rem;
  }
}

.theme-ovo * .account-details_logos > * {
  width: auto;
  height: 4.5rem;
}

.theme-ovo * .account-details_logos-boost {
  content: url('./boost.svg');
}

.theme-ovo * .account-details_logos-ovo {
  content: url('../../themes/ovo/logo.svg');
}
