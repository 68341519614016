@import 'index-ovo.css';
@import 'index-boost.css';

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 15rem;
}

@media screen and (min-width: 768px) {
  html {
    scroll-padding-top: 0;
  }
}

html,
body,
#root {
  margin: 0;
  height: 100%;
  min-height: 100%;
}
