@import 'ErrorNotification-ovo.css';
@import 'ErrorNotification-boost.css';

.error-notification {
  font-size: 1.4rem;
}

.error-notification div div {
  padding: 0.8rem;
}
