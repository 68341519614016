@import 'SupportDetails-ovo.css';
@import 'SupportDetails-boost.css';

.support-details {
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 100%;
  padding-top: 1.5rem;
}

.support-details_exit {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  text-decoration: underline;
  background-color: transparent;
  display: inline-flex;
  align-content: center;
  border: none;
  padding: 0;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.support-details_intro {
  margin-bottom: 2.4rem;
}

@media screen and (min-width: 768px) {
  .support-details_exit {
    font-family: Montserrat, sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background-color: #ffffff;
    border-radius: 0.4rem;
    border: 1px solid #000000;
    padding: 1.5rem 2rem;
    position: relative;
    width: min-content;
  }
}

.support-details > * {
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 0px #00000040;
  margin: 1.5rem 1.5rem 0.5rem;
  padding: 1.5rem;
  font-size: 1.4rem;
}

.support-details_content {
  margin-bottom: 0;
}

.support-details_csat,
.support-details_csat-loading {
  display: none;
}

.support-details_section-active .support-details_csat {
  display: block;
}

@media screen and (min-width: 768px) {
  .support-details > * {
    background-color: transparent;
    max-width: 61.2rem;
    margin: 1.5rem auto 0;
    border: none;
    box-shadow: none;
  }

  .support-details_csat {
    margin-top: 0;
    display: block;
  }

  .support-details_intro {
    display: none;
  }
}

.support-details_top-nav,
.support-details_bottom-nav {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  cursor: pointer;
}

.support-details_bottom-nav {
  display: none;
}

.support-details_section-active .support-details_bottom-nav {
  display: flex;
}

@media screen and (min-width: 768px) {
  .support-details_section-active .support-details_bottom-nav {
    display: none;
  }
}

.support-details_bottom-nav h3 {
  font-size: 2rem;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .support-details_header {
    position: relative;
  }
}

.support-details_section-active .support-details_header {
  display: none;
}

@media screen and (min-width: 768px) {
  .support-details_section-active .support-details_header {
    display: flex;
    flex-direction: column;
  }
}

.support-details_back {
  display: none;
  margin-bottom: 2rem;
}

.support-details_back a {
  display: inline-flex;
  align-content: center;
}

.support-details_section-active .support-details_back {
  display: block;
}

@media screen and (min-width: 768px) {
  .support-details_section-active .support-details_back {
    display: none;
  }
}

.support-details_section {
  display: none;
  scroll-behavior: smooth;
  scroll-margin-top: 2rem;
}

.support-details_active-section {
  display: block;
}

@media screen and (min-width: 768px) {
  .support-details_section {
    display: block;
  }
}

.support-details_on-this-page {
  display: none;
}

@media screen and (min-width: 768px) {
  .support-details_on-this-page {
    display: flex;
    font-size: 2.4rem;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    color: #2f3749;
  }

  .support-details_top-nav {
    gap: 0.8rem;
    margin-bottom: 2.4rem;
  }
}
