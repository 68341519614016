/* Onetrust modal styling */
.theme-boost * #onetrust-pc-sdk,
.theme-boost * #onetrust-banner-sdk {
  font-family: 'Montserrat', sans-serif !important;
}

.theme-boost * #onetrust-policy-text,
.theme-boost * #ot-pc-desc,
.theme-boost * .ot-category-desc {
  font-family: 'Montserrat', sans-serif !important;
}

.theme-boost * #onetrust-policy-text a[href],
.theme-boost * .cookie-setting-link {
  color: #107896 !important;
}

.theme-boost * #onetrust-accept-btn-handler {
  background: #107896 !important;
  color: #ffffff !important;
  border-radius: 2.6rem !important;
  padding-inline: 3rem !important;
}
