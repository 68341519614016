.theme-boost * .header {
  position: relative;
  font-size: 1.4rem;
  background-color: #0f1314;
  height: 5rem;
  margin-bottom: 0.5rem;
}

.theme-boost * .header::before {
  content: '';
  position: absolute;
  left: 0px;
  width: 100%;
  bottom: calc(-0.5rem);
  border-bottom-style: solid;
  border-bottom-color: #ffd404;
  border-bottom-width: 0.3rem;
}

.theme-boost * .header .header_logo {
  transition: all 0.3s ease-out 0s;
}

@media screen and (max-width: 767px) {
  .theme-boost * .header .header_logo {
    content: url('../../themes/boost/small_logo.svg');
    z-index: 1;
    transform: translateY(2.2rem);
    margin-left: 4rem;
  }
}

@media screen and (min-width: 768px) {
  .theme-boost * .header {
    margin-bottom: 0.1rem;
  }

  .theme-boost * .header::before {
    bottom: calc(-0.7rem);
    border-bottom-width: 0.5rem;
  }

  .theme-boost * .header .header_logo {
    content: url('../../themes/boost/logo.svg');
    height: 2.1rem;
    padding: 1.2rem 0rem 2rem 2rem;
  }
}
