@import 'InfoButton-ovo';
@import 'InfoButton-boost';

.info-button {
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.5;
  min-height: 4.6rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-width: 0;
  background: #000000;
  color: #ffffff;
  width: calc(100% - 4rem);
  padding: 0 2rem;
  margin-top: 1rem;
}

.info-button-alternative {
  background: #ffffff;
  color: #000000;
}

.info-button_text {
  display: inline-block;
  margin-right: 0.5rem;
}

@media screen and (min-width: 768px) {
  .info-button {
    width: auto;
    font-size: 1.6rem;
  }
}
