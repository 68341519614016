.theme-ovo * .support-details {
  background-image: url('../../themes/ovo/small_green_bg.svg');
}

.theme-ovo * .support-details_exit {
  color: #0d8426;
}

.theme-ovo * .support-details_exit::before {
  content: url('../../themes/ovo/link_chevron.svg');
  margin-right: 0.5rem;
  padding-top: 0.2rem;
}

.theme-ovo * .support-details_back a {
  color: #0d8426;
}

.theme-ovo * .support-details_back a::before {
  content: url('../../themes/ovo/link_chevron.svg');
  margin-right: 0.5rem;
  margin-top: 2px;
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .theme-ovo * .support-details {
    background-image: url('../../themes/ovo/large_shapes_bg.svg');
  }

  .theme-ovo * .support-details_exit {
    left: -3.3rem;
    border: 1px solid #0d8426;
  }

  .theme-ovo * .support-details_exit::before {
    margin-right: 1rem;
  }

  .theme-ovo * .support-details .app-title {
    text-align: center;
    position: relative;
    width: 71.8rem;
    left: -5.3rem;
  }

  .theme-ovo * .support-details_on-this-page {
    padding-left: 2.5rem;
  }

  .theme-ovo * .support-details_top-nav > .anchor-tag {
    padding-left: 3.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .theme-ovo * .support-details_exit {
    position: absolute;
    left: -17rem;
  }
}
