@import 'AnchorTag-ovo.css';
@import 'AnchorTag-boost.css';

@media screen and (max-width: 767px) {
  .anchor-tag {
    text-decoration: none;
    font-weight: 700;
    line-height: 1.5;
    min-height: 4.6rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-width: 0;
    width: auto;
    cursor: pointer;
  }

  .anchor-tag .anchor-tag_text {
    display: inline-block;
    margin-right: 0.5rem;
  }
}

@media screen and (min-width: 768px) {
  .anchor-tag::before {
    content: url('../../common/images/bullet-point.svg');
    padding-right: 1.3rem;
    vertical-align: 0.2rem;
  }
}
