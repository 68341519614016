.theme-boost * .info-button {
  font-size: 1.6rem;
  border-radius: 2.6rem;
  background: #107896;
  color: #ffffff;
}

.theme-boost * .info-button-alternative {
  background: #107896;
  color: #ffffff;
}

@media screen and (min-width: 768px) {
  .theme-boost * .info-button {
    min-width: 19.2rem;
    padding: 0 0;
  }
}
