@import 'WhdCard-ovo.css';
@import 'WhdCard-boost.css';

.whd-card {
  line-height: 1.5;
}

.whd-card p,
.whd-card_whats-this,
.whd-payment-due-by {
  margin: 0 auto 2.4rem auto;
}

.whd-card_whats-this p {
  margin: 0 auto 1.6rem auto;
}

.whd-card_ineligble h3 {
  margin: 0 auto 0.4rem auto;
}

.whd-card_ineligble p {
  margin: 0 auto 2rem auto;
}

.whd-payment-due-by .whd-card_value {
  margin: 0 0 0.4rem;
}

.whd-card_whats-this > div div {
  background-color: #00000040;
}

.whd-card_advice h3 {
  font-size: 1.6rem;
  color: #2f3749;
  margin: 0 auto 0.8rem auto;
}

.whd-card_label {
  color: #555c6b;
  font-weight: 700;
  margin: 0 auto 0.4rem auto;
}

.whd-card_value {
  color: #2f3749;
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 2.4rem;
}

.whd-card_value-small {
  font-size: 1.6rem;
}

.whd-card_value-check {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (min-width: 480px) {
  .whd-card_value-small {
    font-size: 2rem;
  }
}

@media screen and (min-width: 768px) {
  .whd-card_whats-this p {
    margin: 0 auto 2.4rem auto;
  }
}
