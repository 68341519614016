.theme-boost * .QSI__EmbeddedFeedbackContainer_TextButton {
  background: #ffffff !important;
  color: #107896 !important;
  border: 0.2rem solid #107896 !important;
  border-radius: 8rem !important;
  padding-top: 0.55rem !important;
  padding-bottom: 0.55rem !important;
  max-height: 4.6rem !important;
}

@media screen and (min-width: 768px) {
  .theme-boost * .QSI__EmbeddedFeedbackContainer_TextButton {
    min-width: 19.2rem !important;
  }
}
