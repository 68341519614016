.theme-ovo * .whd-card-closed_notification {
  margin-top: 2.4rem;
  margin-bottom: 0.8rem;
}

.theme-ovo * .whd-card-closed_notification h2 {
  margin-bottom: 0.8rem;
}

.theme-ovo * .whd-card-closed_notification > div > div {
  padding: 1.6rem;
}

@media screen and (min-width: 768px) {
  .theme-ovo * .whd-card-closed_notification {
    margin: 3rem 0 3rem 0;
  }

  .theme-ovo * .whd-card-closed_notification > div > div {
    padding: 3.2rem;
  }
}
