.theme-ovo * .header {
  font-size: 1.5rem;
  padding: 2rem 2rem 2rem;
  background-color: #ffffff;
}

.theme-ovo * .header .header_logo {
  content: url('../../themes/ovo/logo.svg');
  width: 6em;
  height: 4rem;
}
