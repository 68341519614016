/* Onetrust modal styling */
.theme-ovo * #onetrust-pc-sdk,
.theme-ovo * #onetrust-banner-sdk {
  font-family: 'Montserrat', sans-serif !important;
}

.theme-ovo * #onetrust-policy-text,
.theme-ovo * #ot-pc-desc,
.theme-ovo * .ot-category-desc {
  font-family: 'Open Sans', sans-serif !important;
}

.theme-boost * #onetrust-policy-text a[href],
.theme-boost * .cookie-setting-link {
  color: #0d8426 !important;
}

.theme-ovo * #onetrust-accept-btn-handler {
  background: #0d8426 !important;
  color: #ffffff !important;
}
