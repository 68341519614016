.theme-boost * .anchor-tag {
  font-size: 1.6rem;
  font-family: Montserrat, sans-serif;
}

@media screen and (max-width: 767px) {
  .theme-boost * .anchor-tag {
    background: #107896;
    color: #ffffff;
    border-radius: 2.6rem;
  }
}

@media screen and (min-width: 768px) {
  .theme-boost * .anchor-tag {
    color: #107896;
  }
}
