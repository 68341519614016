.theme-boost * .whd-card-closed_notification h2 {
  font-size: 2.4rem;
}

.theme-boost * .whd-card-closed_notification > div > div {
  background-color: #fef7e7;
  border: 0.1rem solid #ffb100;
  border-radius: 0.9rem;
  padding-top: 1rem;
  padding-bottom: 1.6rem;
}

.theme-boost * .whd-card-closed_notification * h2 {
  color: #004687;
  margin-bottom: 1rem;
}

@media screen and (min-width: 480px) {
  .theme-boost * .whd-card-closed_notification p:last-of-type {
    font-size: 1.4rem;
  }
}
