div.loading-modal {
  min-height: auto;
  text-align: center;
  max-width: 80%;
}


.loading-modal_content p {
  font-family: 'Montserrat',sans-serif;
  font-weight: 700;
  color: #555C6B;
}
