@import 'Wrapper-ovo.css';
@import 'Wrapper-boost.css';

.wrapper {
  color: #555c6b;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .wrapper {
    height: 100%;
  }
}

.wrapper main {
  flex: auto;
  background-color: #f9f9f9;
}
