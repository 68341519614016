.theme-boost * .support-details {
  background-image: url('../../themes/boost/small_bg.svg');
  padding-top: 2.5rem;
}

.theme-boost * .support-details_exit {
  color: #107896;
}

.theme-boost * .support-details_exit::before {
  content: url('../../themes/boost/link_chevron.svg');
  margin-right: 0.5rem;
  padding-top: 0.2rem;
}

.theme-boost * .support-details_intro {
  font-family: Montserrat, sans-serif;
  font-size: 1.6rem;
}

.theme-boost * .support-details .app-title h1 {
  font-family: Montserrat, sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
}

.theme-boost * .support-details_back a {
  color: #107896;
}

.theme-boost * .support-details_back a::before {
  content: url('../../themes/boost/link_chevron.svg');
  margin-right: 0.5rem;
  margin-top: 2px;
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .theme-boost * .support-details {
    background-image: url('../../themes/boost/large_bg.svg');
    background-size: auto;
    padding-top: 2.5rem;
  }

  .theme-boost * .support-details_exit {
    border: 2px solid #107896;
    border-radius: 2.6rem;
  }

  .theme-boost * .support-details_exit::before {
    margin-right: 1rem;
    padding-top: 0rem;
  }

  .theme-boost * .support-details .app-title h1 {
    font-family: Baloo, Montserrat;
    font-size: 4.8rem;
    font-weight: 400;
  }

  .theme-boost * .support-details_top-nav > .anchor-tag {
    padding-left: 1.2rem;
  }
}

@media screen and (min-width: 1024px) {
  .theme-boost * .support-details_exit {
    left: -17rem;
  }
}
