@import 'CsatCard-ovo.css';
@import 'CsatCard-boost.css';

.feedback.details-card {
  margin: 0;
}

.QSI__EmbeddedFeedbackContainer {
  margin-top: 0 !important;
}

div.QSI__EmbeddedFeedbackContainer > fieldset {
  display: block;
}

.QSI__EmbeddedFeedbackContainer_QuestionText {
  font-weight: 700 !important;
  font-size: 1.6rem !important;
  line-height: 1.5 !important;
  font-family: Montserrat, sans-serif !important;
}

.QSI__EmbeddedFeedbackContainer_TextButton {
  font-family: Montserrat, sans-serif !important;
  font-weight: 700 !important;
  line-height: 2.1 !important;
  font-size: 1.5rem !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  width: 100% !important;
  display: inline-flex !important;
  justify-content: center !important;
}

.QSI__EmbeddedFeedbackContainer_Stars {
  font-size: 2rem;
  padding-top: 1rem;
  display: block !important;
}

.QSI__EmbeddedFeedbackContainer_OpenText {
  width: 100% !important;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .QSI__EmbeddedFeedbackContainer_Stars {
    padding-top: 2rem;
  }

  .QSI__EmbeddedFeedbackContainer_QuestionText {
    font-weight: 700 !important;
    font-size: 2rem !important;
    line-height: 1.5 !important;
  }

  .QSI__EmbeddedFeedbackContainer_TextButton {
    width: auto !important;
  }
}
